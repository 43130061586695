@font-face {
  font-family: "F37 Judge";
  src: url("/public/fonts/F37Judge-BoldCondensed.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Relative";
  src: url("/public/fonts/RelativeMono10Pitch.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Relative";
  src: url("/public/fonts/RelativeBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bureau Grot";
  src: url("/public/fonts/BureauGrotCompressedBlack.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bureau Grot Extra";
  src: url("/public/fonts/BureauGrotExtraCompressedMedium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SpeziaTrial-WideBold";
  src: url("/public/fonts/SpeziaTrial-WideBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

html, body {
  width: 100%;
  height: 100%;

  
}

body {
  background: #EEE3DE;
  overflow: hidden;

  --base-pad: 12px;
}

@media only screen and (max-width: 1500px) {
  body {
    --base-pad: 12px;
  }
}

#mobile-image-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

#mobile-image-container.hidden {
  display: none;
}

#mobile-image {
  border: 1px solid #222123;
  max-width: 500px;
  max-height: 500px;
  width: calc(100% - 24px);
  margin: 48px auto 12px;
  display: block;
}

#mobile-message {
  max-width: 300px;
  margin: 24px auto;
  padding: 0;
  text-align: center;
  font-family: 'Relative';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222123;
}

#mobile-close {
  outline: none;
  background-color: #EF6F2E;
  height: 24px;
  width: 80px;
  text-align: center;
  border: none;
  font-family: 'Bureau Grot';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto;
  display: block;
  color: #222123;
  cursor: pointer;
}

.App {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  max-width: 900px;
  width: 100%;
  border-left: 1px solid #222123;
  border-right: 1px solid #222123;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer {
  font-family: 'Relative';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-transform: uppercase;
  padding: var(--base-pad) 0 var(--base-pad) var(--base-pad);
  color: #222123;
  margin: 0;
}

.footer a, .footer-nav a {
  color: #EF6F2E;
  text-decoration: none;
}

.footer-nav {
  font-family: 'Relative';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  text-transform: uppercase;
  padding: var(--base-pad) var(--base-pad) var(--base-pad) 0;
  color: #222123;
  margin: 0;
}

.header {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0;
  height: 60px;
  z-index: 1;
  background: #EEE3DE;
  border-bottom: 1px solid #222123;
}

.title {
  position: absolute;
  width: 106px;
  height: 60px;
  left: 0px;
  top: 0px;
  margin: 0;

  background: #222123;

  font-family: 'Bureau Grot Extra';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 63px;
  text-transform: uppercase;
  color: #EEE3DE;
  text-align: center;
}

.type-choice {
  font-family: 'Bureau Grot';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;

  color: #222123;

  height: 50%;
}

.type-choice:first-child {
  border-bottom: 1px solid #222123;
}

.type-selection-container {
  position: absolute;
  width: 92px;
  top: 0;
  bottom: 0;
  left: 106px;
  border-right: 1px solid #222123;
}

.type-choice.selected {
  color: #EF6F2E;
}

.download-button {
  position: absolute;
  right: 12px;
  top: 12px;
  background: #222123;
  outline: none;
  border: none;
  width: 92px;
  height: 36px;
  padding: 3px 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Bureau Grot';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #CCEB84;
}

.download-button svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(-4px, 9px);
}

.content {
  position: absolute;
  top: 60px;
  left: 0;
  border-top: 1px solid #222123;
  border-bottom: 1px solid #222123;
  height: 301px;
  z-index: 1;
  overflow: hidden;
}

.content.PFP {
  border-left: 1px solid #222123;
  border-right: 1px solid #222123;
  height: 66vw;
  width: 66vw;
  max-width: 301px;
  max-height: 301px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1500px) {
  .content {
    height: min(calc(33.3333333vw + 1px), 301px);
  }
}

#banner {
  transform-origin: top left;
  position: relative;
}

#hidden-banner {
  position: fixed;
  top: -1000px;
  left: -2000px;
}

#banner-text {
  font-family: 'Bureau Grot Extra';
  font-style: normal;
  font-weight: 400;
  font-size: 420px;
  line-height: 500px;
  text-align: right;
  text-transform: uppercase;

  color: #222123;
}

#head_2 > rect {
  width: 206.75px;
}

.PFP-banner #head_2 > rect,
.PFP #head_2 > rect {
  width: 211.75px;
}

.controls-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 363px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.controls-container.PFP {
  top: min(calc(66vw + 61px), 362px);
  border-top: 1px solid #222123;
}

.swatches-picker > div {
  z-index: 1;
}

.controls {
  padding: calc(var(--base-pad) * 2);
}

@media only screen and (max-width: 1500px) {
  .controls-container  {
    top: min(calc(33.3333333vw + 63px), 363px);
  }

  .controls-container.PFP {
    top: min(calc(66vw + 61px), 362px);
  }
}

.controls-title {
  height: 37px;
  font-family: 'Bureau Grot';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 37px;
  text-transform: uppercase;
  color: #EF6F2E;
  border-bottom: 1px solid #222123;
  padding: 0 var(--base-pad);
}

.controls-row {
  border-bottom: 1px solid #222123;
  display: flex;
}

.controls-label {
  display: flex;
  font-family: 'Relative';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-transform: uppercase;
  padding: var(--base-pad);
  color: #222123;
  width: 135px;
  min-width: 135px;
  justify-content: flex-end;
  white-space: nowrap;

  border-right: 1px solid #222123;
}

.controls-value {
  display: flex;
  flex: 1;
  font-family: 'Relative';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  padding: var(--base-pad);
  color: #222123;
}

.controls-row:last-child {
  border-bottom: none;
}

.banner-controls, .subject-controls {
  border: 1px solid #222123;
  margin-bottom: calc(var(--base-pad) * 2);
}

.controls-input {
  margin: -12px;
  padding: var(--base-pad);
  background: none;
  outline: none;
  border: none;
  font-family: 'Relative';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  flex: 1;
}

.color-container {
  position: relative;
  display: flex;
  flex: 1;
}

.selected-color {
  width: 24px;
  height: 24px;
  /* border-radius: 24px; */
  outline: none;
  margin-right: 6px;

  border: 1px solid #222123;
  box-sizing: border-box;
  cursor: pointer;
}

.swatch-picker {
  position: absolute;
  top: calc(100% + 6px);
  left: -149px;
  border: 1px solid #222123;
}

.swatch-picker:before {
  content: '';
  display: block;
  background-color: #222123;
  width: 5px;
  height: 5px;
  transform: rotate(45deg) translateX(-50%);
  position: absolute;
  left: calc(50% - 1px);
  top: -1px;
}

.choice {
  display: flex;
  height: 24px;
  line-height: 24px;
  margin-bottom: 6px;
  cursor: pointer;
}

.choice:last-child {
  margin-bottom: 0;
}

.choice:before {
  content: '';
  width: 12px;
  height: 12px;
  margin-top: 5px;

  border: 1px solid #222123;
  box-sizing: border-box;
  margin-right: 12px;
}

.choice.selected:before {
  background: #E9B9F3;
}